<template>
  <section class="srp">
    <FiltersSidebar v-if="windowWidth > 1025" />

    <FiltersMobileMenubar v-if="windowWidth <= 1025" />

    <div class="srp__content-container">
      <FilterTags />

      <div v-if="!showNoResults" class="srp__content">
        <div
            class="row no-wrap items-center q-mb-md"
            style="position: relative; z-index: 1"
        >
          <div class="row no-wrap items-center" v-if="!searchLoading">
            <q-icon name="sym_r_manage_search" size="24px" class="q-mr-sm" />
            <p class="q-ma-none">
              Located
              <span class="text-weight-bold">{{
                  formatNumber(searchData.pagination.totalResults)
                }}</span>
              listings
              {{
                $store.getters["search/locationCityStateFormatted"] ==
                "Nationwide"
                    ? ""
                    : " near "
              }}
              <span class="text-weight-bold">{{
                  $store.getters["search/locationCityStateFormatted"]
                }}</span>
            </p>
          </div>
          <q-skeleton v-else type="rect" width="150px" animation="fade" />

          <q-space></q-space>
          <q-btn-dropdown
              v-show="windowWidth > 1025"
              ref="sortDropdown"
              no-caps
              color="background-light"
          >
            <template v-slot:label>
              <div class="row no-wrap items-center">
                <!--<q-icon name="sym_r_swap_vert" size="20px" class="q-mr-sm" style="margin-left: -16px;"/>-->
                <div class="column items-start justify-center">
                  <p
                      class="text-weight-medium"
                      style="font-size: 11px; line-height: 11px; color: rgba(255, 255, 255, 0.7); margin-bottom: 4px"
                  >
                    Sort By
                  </p>
                  <p class="text-weight-medium" style="line-height: 1">
                    {{ $store.getters["search/activeSortObject"].label }}
                  </p>
                </div>
              </div>
            </template>
            <q-card-section class="q-pb-none" style="min-width: 250px">
              <q-tabs
                  align="left"
                  narrow-indicator
                  indicator-color="secondary"
                  dense
                  class="--tab-pills full-width q-pb-none q-mb-md"
                  v-model="sortMode"
              >
                <q-tab
                    name="classic"
                    label="Classic"
                    :ripple="false"
                    style="width: 50%"
                />
                <q-tab
                    name="advanced"
                    label="Advanced"
                    :ripple="false"
                    style="width: 50%"
                />
              </q-tabs>
            </q-card-section>

            <q-tab-panels
                v-model="sortMode"
                animated
                class="bg-background-light"
            >
              <q-tab-panel name="classic" class="q-pa-none">
                <q-form @submit="reloadVehicleListingsComponent">
                  <q-list dense class="q-pb-md">
                    <q-item
                        v-for="(option, i) in $store.state.search.sort.options
                        .classic"
                        :key="i + '_searchSortFilterOption'"
                        :inset-level="0"
                        v-ripple
                        dense
                        :disable="searchLoading"
                        tag="label"
                        style="padding-left: 0 !important;"
                        @click="reloadVehicleListingsComponent"
                    >
                      <q-item-section side class="q-px-sm">
                        <q-radio
                            v-model="sortSelectModel"
                            :val="option.value"
                            color="secondary"
                            :disable="searchLoading"
                            @click="reloadVehicleListingsComponent"
                        />
                      </q-item-section>
                      <q-item-section>
                        {{ option.label }}
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-form>
              </q-tab-panel>

              <q-tab-panel name="advanced" class="q-pa-none">
                <q-form @submit="reloadVehicleListingsComponent">
                  <q-list class="q-pb-md">
                    <q-item
                        v-for="(option, i) in $store.state.search.sort.options
                        .advanced"
                        :key="i + '_SrpSortFilterOption'"
                        :inset-level="0"
                        v-ripple
                        dense
                        :disable="searchLoading"
                        tag="label"
                        style="padding-left: 0 !important;"
                        @click="reloadVehicleListingsComponent"
                    >
                      <q-item-section side class="q-px-sm">
                        <q-radio
                            v-model="sortSelectModel"
                            :val="option.value"
                            color="secondary"
                            :disable="searchLoading"
                            @click="reloadVehicleListingsComponent"
                        />
                      </q-item-section>
                      <q-item-section>
                        {{ option.label }}
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-form>
              </q-tab-panel>
            </q-tab-panels>
          </q-btn-dropdown>
        </div>

        <component
            v-bind:is="'jfpc-vehicle-listings-custom'"
            v-if="showVehicleListingsComponent"
            v-show="!searchLoading"
            publisher-account-id="10001"
            placement="srp"
            theme="carscience"
            :infinite-scroll="enableInfiniteScroll"
            :per-page="20"
            :current-page="searchData.pagination.currentPage"
            :filters.prop="searchFilters"
            :options.prop="searchOptions"
            :sorting.prop="searchSorting"
            :channel-one="
            urlParams().get('utm_campaign')
              ? urlParams().get('utm_campaign')
              : ''
          "
            dark-mode
            @loaded-totals="
            e => {
              $store.commit('search/SET_PAGINATION_TOTALS', e.detail[0]);
              $store.commit('search/SET_LOADED');
            }
          "
            @vehicle-clicked="e => fireViewContentEvents(e.detail[0].bid_value)"
        ></component>

        <div v-show="searchLoading" class="row q-col-gutter-lg">
          <q-card
              v-for="i in 20"
              :key="i + '_srpListingPlaceholder'"
              class="col-12 col-sm-6 col-md-4 col-lg-3"
              flat
          >
            <q-skeleton
                square
                animation="fade"
                class="full-width"
                style="aspect-ratio: 4/3"
            />

            <q-card-section>
              <q-skeleton type="text" class="text-subtitle1" animation="fade" />
              <q-skeleton
                  type="text"
                  width="40%"
                  class="text-subtitle"
                  animation="fade"
                  style="margin-top: -6px"
              />
            </q-card-section>
            <q-card-section class="flex justify-between items-center q-py-none">
              <q-skeleton
                  type="text"
                  width="30%"
                  class="text-subtitle1"
                  animation="fade"
              />
              <q-skeleton
                  type="text"
                  width="20%"
                  class="text-subtitle1"
                  animation="fade"
              />
            </q-card-section>
          </q-card>
        </div>

        <!-- Pagination -->
        <div
            v-show="(searchData.pagination.totalResults > 0 || searchLoading) && enableInfiniteScroll === false"
            class="srp__pagination q-mt-xl"
        >
          <div class="flex no-wrap items-center">
            <div class="flex no-wrap">
              <q-btn
                  color="background-light"
                  text-color="text-light"
                  unelevated
                  icon="sym_r_first_page"
                  :ripple="false"
                  :disable="
                  searchData.pagination.disableAll ||
                    searchData.pagination.disablePrevious ||
                    searchLoading
                "
                  @click="navigateToPage('first')"
                  class="--desktop-only-content q-mr-sm q-icon-btn"
              />
              <q-btn
                  color="background-light"
                  text-color="text-light"
                  unelevated
                  label="Previous"
                  :ripple="false"
                  :disable="
                  searchData.pagination.disableAll ||
                    searchData.pagination.disablePrevious ||
                    searchLoading
                "
                  @click="navigateToPage('previous')"
              />
            </div>
            <q-space />
            <div v-show="windowWidth > 400" class="--desktop-only-content">
              <div
                  v-if="!searchLoading"
                  v-html="
                  $store.getters['search/currentPaginationRangeBlurb'](false)
                "
                  class="text-text-light q-mx-sm text-center"
              />
              <q-skeleton v-else type="rect" width="150px" animation="fade" />
            </div>
            <div v-show="windowWidth > 300" class="--mobile-only-content">
              <div
                  v-if="!searchLoading"
                  v-html="
                  $store.getters['search/currentPaginationRangeBlurb'](true)
                "
                  class="text-text-light q-mx-sm --text-sm text-center"
              />
              <q-skeleton v-else type="rect" width="100px" animation="fade" />
            </div>
            <q-space />
            <div class="flex no-wrap">
              <q-btn
                  color="secondary"
                  label="Next"
                  :ripple="false"
                  :disable="
                  searchData.pagination.disableAll ||
                    searchData.pagination.disableNext ||
                    searchLoading
                "
                  @click="navigateToPage('next')"
              />
              <q-btn
                  color="secondary"
                  icon="sym_r_last_page"
                  :ripple="false"
                  :disable="
                  searchData.pagination.disableAll ||
                    searchData.pagination.disableNext ||
                    searchLoading
                "
                  @click="navigateToPage('last')"
                  class="--desktop-only-content q-ml-sm q-icon-btn"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="srp__content">
        <div class="text-weight-bold text-center q-my-xl">
          <q-icon
              name="sym_r_search_off"
              size="75px"
              class="q-mb-md text-text-light"
          />
          <h5>
            We couldn't locate any listings matching your search.
          </h5>
        </div>

        <div class="q-gutter-y-lg q-mt-xl">
          <div
              v-for="(publisherComponent,
            i) in emptySearchResultsPublisherComponents"
              :key="publisherComponent.key + '_emptySearchPublisherComponent'"
              :class="{
              'q-mt-none': i === 0
            }"
              class="q-pb-md"
          >
            <VehicleListingsPublisherComponentSection
                :publisher-component-data="publisherComponent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FiltersSidebar from "@/components/FiltersSidebar";
import FilterTags from "@/components/FilterTags";
import FiltersMobileMenubar from "@/components/FiltersMobileMenubar";
import VehicleListingsPublisherComponentSection from "@/components/UI/VehicleListingsPublisherComponentSection.vue";
import { reactive } from "@vue/reactivity";

export default {
  name: "AutoSrp",
  components: {
    VehicleListingsPublisherComponentSection,
    FiltersMobileMenubar,
    FilterTags,
    FiltersSidebar
  },
  data() {
    return {
      showVehicleListingsComponent: true,
      enableInfiniteScroll: true,
      sortMode: this.$store.state.search.sort.model.includes("classic")
          ? "classic"
          : "advanced",
      emptySearchResultsPublisherComponents: [
        {
          title: "Best Matches For You",
          component: "jfpc-vehicle-listings-visitor-recommended",
          hide: !window.localStorage.getItem("jfpc-clicked-vehicles"),
          hideHeader: true,
          seeMoreAction: () => {},
          key: Math.random() * 100000
        },
        {
          title: "Recently Viewed",
          component: "jfpc-vehicle-listings-visitor-recently-clicked",
          hide: false,
          hideHeader: true,
          seeMoreAction: () => {},
          key: Math.random() * 100000
        },
        {
          title: "Popular On CarScience",
          component: "jfpc-vehicle-listings-most-clicked",
          hide: false,
          hideHeader: true,
          seeMoreAction: () =>
              (this.$store.state.search.sort.model =
                  "advanced.popular_on_carscience"),
          key: Math.random() * 100000
        }
      ]
    };
  },
  computed: {
    searchLoading() {
      return !this.$store.state.search.loaded;
    },
    searchData() {
      return reactive({
        keyword: this.$store.state.search.keyword,
        filters: this.$store.state.search.filter.modelsActive,
        location: this.$store.state.search.location,
        sort: this.$store.state.search.sort,
        pagination: this.$store.state.search.pagination
      });
    },
    searchFilters() {
      let filters = [
        {
          mileage: {
            gte: this.searchData.filters["mileage"].min,
            lte: this.searchData.filters["mileage"].max
          }
        },
        {
          price: {
            gte: this.searchData.filters["price"].min,
            lte: this.searchData.filters["price"].max
          }
        },
        {
          year: {
            gte: this.searchData.filters["year"].min,
            lte: this.searchData.filters["year"].max
          }
        }
      ];

      let orFilters = [];
      let orFilterKeys = ["make", "model"];
      Object.keys(this.searchData.filters).forEach(filterModelKey => {
        if (["mileage", "price", "year"].includes(filterModelKey)) return;

        let filterInArray = this.searchData.filters[filterModelKey];
        if (filterModelKey === "make") {
          filterInArray = [];
          this.searchData.filters[filterModelKey].forEach(makeId => {
            filterInArray.push(
                this.$store.state.vehicleMakeModels.makeIdNameMap[makeId]
            );
          });
        } else if (filterModelKey === "model") {
          filterInArray = [];
          this.searchData.filters[filterModelKey].forEach(modelId => {
            filterInArray.push(
                this.$store.state.vehicleMakeModels.modelIdNameMap[modelId]
            );
          });
        }

        if (filterInArray.length > 0) {
          let filterKey = this.convertCamelToSnake(filterModelKey);
          if (filterKey == "color") {
            filterKey = "exterior_color";
          }
          if (orFilterKeys.includes(filterKey)) {
            orFilters.push({ [filterKey]: { in: filterInArray } });
          } else {
            filters.push({ [filterKey]: { in: filterInArray } });
          }
        }
      });

      if (orFilters.length > 0) {
        filters.push({ or: orFilters });
      }

      return filters;
    },
    searchSorting() {
      return this.$store.getters["search/activeSortObject"].searchObjects;
    },
    searchOptions() {
      let options = {
        location_search: {
          postal_code: this.searchData.location.postalCode,
          radius: this.searchData.location.radius,
          include_distance_field: true
        },
        deduplicate_field: ["vin"]
      };

      if (this.searchData.keyword.length > 0) {
        options.keyword_search = {
          keyword: this.searchData.keyword
        };
      }

      return options;
    },
    sortSelectModel: {
      get() {
        return this.searchData.sort.model;
      },
      set(val) {
        this.$store.commit("search/RESET_PAGINATION");
        this.$store.commit("search/SET_SORT", val);
        this.$store.commit("search/SET_LOADING");
      }
    },
    showNoResults() {
      return (
          !this.searchLoading && this.searchData.pagination.totalResults == 0
      );
    }
  },
  watch: {
    searchLoading(newValue) {
      if (newValue === true) {
        this.reloadVehicleListingsComponent();
      }
    }
  },
  mounted() {
    this.$store.commit("search/SET_URL_PARAMS");
    // this.reloadVehicleListingsComponent();
  },
  methods: {
    reloadVehicleListingsComponent() {
      this.backToTop("instant");
      this.showVehicleListingsComponent = false;
      setTimeout(() => {
        this.showVehicleListingsComponent = true;
        this.$store.commit("search/SET_URL_PARAMS");
        this.$refs.sortDropdown.hide();
      }, 2000);
    },
    navigateToPage(page) {
      this.$store.commit("search/SET_PAGE", page);
      this.$store.commit("search/SET_LOADING");
      this.reloadVehicleListingsComponent();
    }
  }
};
</script>

<style scoped lang="scss">
.srp {
  @include full-screen-height;
  @include display-flex(row, flex-start, flex-start);
  @include contain-content-width;

  @include screen-md {
    @include display-flex(column, flex-start, flex-start);
  }

  .srp__content-container {
    width: 100%;
    flex: 1 1 0;
    min-width: 0;
    padding: $space-lg 0;

    @include screen-md {
      padding: $space-lg;
    }

    .srp__content {
      border-radius: 8px;
    }
  }
}
</style>